import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, sendTo: String, title: String }


  connect() {
    window.dataLayer = window.dataLayer || [];
    const url = this.urlValue
    const sendTo = this.sendToValue
    const title = this.titleValue

    function gtag() {
      dataLayer.push(arguments);
    }

    if (typeof gtag === "function") {
      console.log("gtag conversion running")
      gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'u6': url,
        'send_to': sendTo
      });
    } else {
      console.warn('gtag is not defined');

    }

    // Initialize Facebook Pixel (FBQ)
    if (title && typeof fbq === "function" ) {
      fbq('trackCustom', title, {
        content_category: title
      });
    } else {
      console.warn('fbq is not defined');
    }
  }
}

